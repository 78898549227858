import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import { Const } from './const';
import { TravelOptionStrage } from '../providers/servicer/models/storage/travel-option-storage';
import { TripDestinationStrage } from '../providers/servicer/models/storage/trip-destination-storage';

@Injectable({ providedIn: 'root' })
export class StorageWrapper {
    private _storage: Storage | null = null;

    constructor(private storage: Storage) {
    }

    async init() {
      const storage = await this.storage.create();
      this._storage = storage;
    }

    /*** ストレージから値取得 ***/
    async getStorage(key: string) {
        return await this._storage.get(key).then((val) => {
            console.log("storage get: { key:" + key, ",value:" + val + "}");
            return val;
        });
    }

    /*** ストレージへ保存 ***/
    async setStorage(key: string, value: any) {
        console.log("storage set: { key:" + key + ",value:" + value + "}");
        await this._storage.set(key, value);
    }

    /*** ストレージから除去 ***/
    async removeStorage(key: string) {
        console.log("storage remove: key:" + key);
        await this._storage.remove(key);
    }

    /*** ストレージ初期化 ***/
    async resetStorage() {
        console.log("storage reset");
        await this._storage.clear();
    }

    /*** ストレージの全情報ログ出力 ***/
    async getStorageLog() {
        console.log("storage info");
        await this._storage.forEach((key, value) => {
            console.log("key:" + key + ",value:" + value);
        });
    }


    /*
    * 以降、ユーザIDに関連する特別なラッパー群
    * ログインしたユーザに関連する情報にアクセスできるようになっている。
    */

    private userId: string = null;

    async setUserId(userId: string) {
        this.userId = userId;
        await this.setStorage(Const.STORAGE_KEY_USER_ID, userId);
    }

    async getUserId() {
        if (this.userId != null) {
            // 下記のPromiseを使って正式に書いた方が正しいが、直接値を返却してもthenで取れていたので、このままとする。
            // return new Promise<string>((resolve) => { resolve(this.userId); });
            return this.userId;
        }
        return await this.getStorage(Const.STORAGE_KEY_USER_ID);
    }

    async setReserveType(reserveType: number) {
        await this.setStorage((this.userId + "." + Const.STORAGE_KEY_RESERVE_TYPE), reserveType);
    }

    async getReserveType() {
        return await this.getStorage((this.userId + "." + Const.STORAGE_KEY_RESERVE_TYPE));
    }

    async setTripHistory(tripHistory: any) {
        await this.setStorage((this.userId + "." + Const.STORAGE_KEY_TRIPHISTORY), tripHistory);
    }

    async getTripHistory() {
        return await this.getStorage((this.userId + "." + Const.STORAGE_KEY_TRIPHISTORY));
    }

    async setTravelOption(travelOption: TravelOptionStrage) {
        await this.setStorage((this.userId + "." + Const.STORAGE_KEY_TRAVEL_OPTION), travelOption);
    }

    async getTravelOption(): Promise<TravelOptionStrage> {
        return await this.getStorage((this.userId + "." + Const.STORAGE_KEY_TRAVEL_OPTION));
    }

    async setTripDestination(tripDestination: TripDestinationStrage) {
        await this.setStorage((this.userId + "." + Const.STORAGE_KEY_TRIP_DESTINATION), tripDestination);
    }

    async getTripDestination(): Promise<TripDestinationStrage> {
        return await this.getStorage((this.userId + "." + Const.STORAGE_KEY_TRIP_DESTINATION));
    }

    async setTripOrigin(tripOrigin: any) {
        await this.setStorage((this.userId + "." + Const.STORAGE_KEY_TRIP_ORIGIN), tripOrigin);
    }

    async getTripOrigin() {
        return await this.getStorage((this.userId + "." + Const.STORAGE_KEY_TRIP_ORIGIN));
    }

    async setVehicleId(vehicleId: number) {
        await this.setStorage((this.userId + "." + Const.STORAGE_KEY_VEHICLE_ID), vehicleId);
    }

    async getVehicleId() {
        return await this.getStorage((this.userId + "." + Const.STORAGE_KEY_VEHICLE_ID));
    }

    async removeVehicleId() {
        await this.removeStorage((this.userId + "." + Const.STORAGE_KEY_VEHICLE_ID));
    }

    async setToken(token: string) {
        await this.setStorage((this.userId + "." + Const.STORAGE_KEY_TOKEN), token);
    }

    async getToken() {
        return await this.getStorage((this.userId + "." + Const.STORAGE_KEY_TOKEN));
    }

    async removeToken() {
        await this.removeStorage((this.userId + "." + Const.STORAGE_KEY_TOKEN));
    }

    async setVehicleArrivedMinute(vehicleArrivedMinute: number) {
        await this.setStorage((this.userId + "." + Const.STORAGE_KEY_VEHICLE_ARRIVED_MINUTE), vehicleArrivedMinute);
    }

    async getVehicleArrivedMinute() {
        return await this.getStorage((this.userId + "." + Const.STORAGE_KEY_VEHICLE_ARRIVED_MINUTE));
    }

    async removeVehicleArrivedMinute() {
        await this.removeStorage((this.userId + "." + Const.STORAGE_KEY_VEHICLE_ARRIVED_MINUTE));
    }

    async setStationHistory(stationCode:string){
        let stationCodes = await this.getStorage((this.userId + "." + Const.STORAGE_KEY_STATION_HISTORY));
        if(stationCodes == null) stationCodes = [];
        while(stationCodes.indexOf(stationCode) >= 0) {
          stationCodes.splice(stationCodes.indexOf(stationCode), 1);
        }
        stationCodes.unshift(stationCode);

        while(stationCodes.length > 10) {
          stationCodes.pop();
        }
        await this.setStorage((this.userId + "." + Const.STORAGE_KEY_STATION_HISTORY), stationCodes);
    }

    async getStationHistory() {
        return await this.getStorage((this.userId + "." + Const.STORAGE_KEY_STATION_HISTORY));
    }

    /** 予約完了後、乗車地を情報を履歴として残す (FY22事前予約対応) */
    async setPuStationHistory(stationCode:string){
        let stationCodes = await this.getStorage((this.userId + "." + Const.STORAGE_KEY_PUSTATION_HISTORY));
        if(stationCodes == null) stationCodes = [];
        while(stationCodes.indexOf(stationCode) >= 0) {
          stationCodes.splice(stationCodes.indexOf(stationCode), 1);
        }
        stationCodes.unshift(stationCode);

        while(stationCodes.length > 10) {
          stationCodes.pop();
        }
        await this.setStorage((this.userId + "." + Const.STORAGE_KEY_PUSTATION_HISTORY), stationCodes);
    }

    /** 履歴として残っている乗車地の情報を渡す (FY22事前予約対応) */
    async getPuStationHistory() {
        return await this.getStorage((this.userId + "." + Const.STORAGE_KEY_PUSTATION_HISTORY));
    }

    /*** ユーザー名の保存 ***/
    async setUserName(value: string) {
      await this.setStorage(Const.STORAGE_KEY_USER_NAME, value);
    }

    async setAdMode(adMode: boolean) {
        await this.setStorage((this.userId + "." + Const.STORAGE_KEY_AD_MODE), adMode);
    }

    async getAdMode() {
        return await this.getStorage((this.userId + "." + Const.STORAGE_KEY_AD_MODE));
    }

    async removeAdMode() {
        await this.removeStorage((this.userId + "." + Const.STORAGE_KEY_AD_MODE));
    }

    /** 仮日付情報を保存 - 時間設定画面にて使用 (FY22事前予約対応) */
    async setSelectTempDayTime(day: Date) {
        await this.setStorage((this.userId + "." + Const.STORAGE_KEY_SELECT_TEMP_DAY_TIME), day);
    }

    /** 仮日付情報を修得 - 時間設定画面にて使用 (FY22事前予約対応) */
    async getSelectTempDayTime() {
        return await this.getStorage((this.userId + "." + Const.STORAGE_KEY_SELECT_TEMP_DAY_TIME));
    }

    /** 仮日付情報を削除 - 時間設定画面にて使用 (FY22事前予約対応) */
    async removeSelectTempDayTime() {
        await this.removeStorage((this.userId + "." + Const.STORAGE_KEY_SELECT_TEMP_DAY_TIME));
    }

    /** 確定日付情報を保存 (FY22事前予約対応) */
    async setSelectDayTime(day: Date) {
        await this.setStorage((this.userId + "." + Const.STORAGE_KEY_SELECT_DAY_TIME), day);
    }

    /** 確定日付情報を修得 (FY22事前予約対応) */
    async getSelectDayTime() {
        return await this.getStorage((this.userId + "." + Const.STORAGE_KEY_SELECT_DAY_TIME));
    }

    /** 確定日付情報を削除 (FY22事前予約対応) */
    async removeSelectDayTime() {
        await this.removeStorage((this.userId + "." + Const.STORAGE_KEY_SELECT_DAY_TIME));
    }


}
